<template>
  <div
    class="card-item flex flex-align-center flex-justify-between ptb10 plr15 bgf mb10"
  >
    <div class="flex flex-align-center">
      <van-image
        width="70px"
        height="70px"
        class="mr10"
        :src="goodsItem.thumb_img"
      />
      <div class="card-info">
        <div class="info-title fs14 cl17 mb10 txt-line-1 fw500">
          {{ goodsItem.title }}
        </div>
        <div class="info-row mb5 fs12">
          <span class="info-label cl17">库存：</span>
          <span class="info-value cl8c">{{ goodsItem.stock }}</span>
        </div>
        <div class="info-row fs12">
          <span class="info-label cl17">价格：</span>
          <span class="info-value cl8c">{{ goodsItem.score }} 积分</span>
        </div>
      </div>
    </div>
    <div class="share-btn flex-g0 flex-s0">
      <van-button
        size="small"
        plain
        color="#888"
        @click="onShare(goodsItem, member_id)"
      >
        分享
      </van-button>
    </div>
  </div>
</template>

<script>
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { Button, Image as VanImage } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [VanImage.name]: VanImage
  },
  props: {
    goodsItem: {
      type: Object,
      default: () => {}
    },
    member_id: {
      type: String,
      default: ''
    }
  },
  setup() {
    async function onShare(item, member_id) {
      let title = item.title
      let imgUrl = item.thumb_img
      let page = `pages/user/exchange/detail/index.html?id=${item.id}&type=${item.goods_type}`
      // let page = `pagesIntegral/user/exchange/detail/index.html?id=${item.id}&type=${item.goods_type}&member_id=${member_id}`

      let shareObj = {
        appid: 'wxbecd55f783f6a0cd', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
        title,
        imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
      }
      // console.log('发送前参数', shareObj)
      await sendChatMessageAllType('miniprogram', {
        miniprogram: { ...shareObj }
      })
    }
    return {
      onShare
    }
  }
}
</script>

<style lang="less">
.card-item {
  // 限制标题长度
  .info-title {
    max-width: calc(100vw - 170px);
  }
}
</style>
