<template>
  <div class="image-page">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :offset="100"
      :success-duration="1300"
    >
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>加载中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        class="pt10"
        :immediate-check="false"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        error-text="请求失败，点击重新加载"
        @load="onNext"
      >
        <ImageItem
          v-for="item in list"
          :key="item.id"
          :activityItem="item"
          :member_id="member_id"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import ImageItem from './components/ImageItem.vue'
import moment from 'moment'
import { pagination } from './pagination'
import { List, PullRefresh, Icon } from 'vant'
import { getShareActivityList } from '../../service/checkQr.service'
export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    ImageItem
  },
  setup() {
    // 获取轮播图列表
    // const getList = () => {
    //   const {data, code} = await getShareActivityList()
    // }
    const {
      list,
      params,
      loading,
      finished,
      member_id,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getShareActivityList, { pageMode: true })

    // 刷新条时间
    const nowDate = () => {
      return moment(new Date()).format('HH:mm:ss')
    }

    return {
      params,
      list,
      loading,
      finished,
      member_id,
      refreshing,
      error,
      nowDate,
      onRefresh,
      onNext
    }
  },

  created() {
    this.onRefresh()
  }
}
</script>
