<template>
  <div class="share-page">
    <van-tabs
      v-model:active="activePage"
      color="#1773FA"
      title-active-color="#1773FA"
      line-width="18px"
      @change="changeTab"
      sticky
    >
      <van-tab title="轮播图" name="image" title-class="tab-title">
        <IamgeList v-if="activePage == 'image'" />
      </van-tab>
      <van-tab title="商品" name="goods" title-class="tab-title">
        <GoodsList v-if="activePage == 'goods'" />
      </van-tab>
      <van-tab
        v-if="isRegistMall && customer_id"
        title="订单"
        name="order"
        title-class="tab-title"
      >
        <OrderList v-if="activePage == 'order'" :customer_id="customer_id" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { checkRegistMall } from '../../service/checkQr.service'
import IamgeList from './iamgeList.vue'
import GoodsList from './goodsList.vue'
import OrderList from './orderList.vue'
import { getCurExternalContact } from '@/utils/nwechat.js'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    IamgeList,
    GoodsList,
    OrderList
  },
  setup() {
    const activePage = ref('image')
    const route = useRoute()
    const router = useRouter()

    const changeTab = () => {
      router.push({
        query: {
          type: activePage.value
        }
      })
    }

    onMounted(() => {
      activePage.value = route.query.type
    })

    return {
      activePage,
      changeTab
    }
  },
  data() {
    return {
      requestStatus: null,
      pollingIndex: 0,
      customer_id: '',
      isRegistMall: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // 轮询请求id
      this.requestStatus = setInterval(() => {
        if (this.customer_id || this.pollingIndex > 3) {
          // 赋值给参数
          // this.customer_userid = this.customer_id
          this.checkCustomer()
          clearInterval(this.requestStatus)
          return
        }
        console.log('现在是第', this.pollingIndex)
        this.pollingIndex++
        getCurExternalContact()
          .then((res) => {
            this.customer_id = res
          })
          .catch(() => {
            this.customer_id = ''
          })
      }, 300)
    },

    // 客户是否注册积分商城
    async checkCustomer() {
      const { data, code } = await checkRegistMall({
        customer_userid: this.customer_id
      })
      if (code == 200 && data.is_exits) {
        this.isRegistMall = true
      }
    }
  }
}
</script>

<style lang="less">
.share-page {
  // tab栏高度
  .van-tabs__wrap {
    height: 50px;
    border-bottom: 1px solid #f5f5f5;
  }
  // 分享按钮
  .share-btn {
    .van-button {
      height: 30px;
      line-height: 28px;
    }
    .van-button__content {
      min-width: 32px;
    }
  }
}

// .tab-title {
//   line-height: 34px;
// }
</style>

<style lang="less"></style>
