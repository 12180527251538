<template>
  <div class="order-item plr15 bgf bt10 mb10">
    <div class="order-item-header flex flex-justify-between fs12 cl26">
      <div>订单编号：{{ orderItem.order_no }}</div>
      <div>
        状态：
        <span class="txt-blue">{{ showStatus(orderItem) }}</span>
      </div>
    </div>
    <div class="order-info">
      <div class="order-info-main ptb10 flex">
        <van-image
          width="60px"
          height="60px"
          class="mr10 flex-g0 flex-s0"
          :src="orderItem.order_goods_img"
        />
        <div class="flex flex-direction-column flex-justify-between">
          <div class="order-title fs14 cl26 txt-line-2 fw500">
            {{ orderItem.goods_title }}
          </div>
          <div class="fs12 flex flex-justify-between">
            <div class="goods-num cl99">x {{ orderItem.goods_num }}</div>
            <div class="order-price cl26">{{ orderItem.goods_score }} 积分</div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-item-footer flex flex-justify-between flex-align-center">
      <div class="cus-info fs12 cl26 ptb5">
        <div class="mb5">兑换人：{{ orderItem.user_name }}</div>
        <div>兑换时间：{{ orderItem.verification_time }}</div>
        <div v-if="orderItem.create_time" class="mt5">
          物流单号：{{ orderItem.create_time }}
        </div>
      </div>
      <div class="share-btn flex-g0 flex-s0">
        <van-button
          size="small"
          plain
          color="#888"
          @click="onShare(orderItem, member_id)"
        >
          分享
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { Button, Image as VanImage } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [VanImage.name]: VanImage
  },
  props: {
    orderItem: {
      type: Object,
      default: () => {}
    },
    member_id: {
      type: String,
      default: ''
    }
  },
  setup() {
    // 状态展示
    const showStatus = (item) => {
      let obg = {
        wait_use: '待使用',
        used: '已使用',
        failure: '已失效',
        wait_send: '待发货',
        has_shipped: '已发货',
        has_completed: '已完成'
      }
      return obg[item.status]
    }
    // 分享
    async function onShare(item, member_id) {
      let title = item.goods_title
      let imgUrl = item.order_goods_img
      let page = ''
      if (item.order_type == 'logistics') {
        page = `pages/order/detail.html?order_id=${item.id}&type=${item.order_type}`
        // page = `pagesIntegral/order/detail.html?score_goods_id=${item.score_goods_id}&goods_type=${item.order_type}&member_id=${member_id}`
      } else {
        page = `pages/user/exchange/qr_show/index.html?order_id=${item.id}&type=${item.order_type}`
        // page = `pagesIntegral/user/exchange/qr_show/index.html?score_goods_id=${item.score_goods_id}&goods_type=${item.order_type}&member_id=${member_id}`
      }

      let shareObj = {
        appid: 'wxbecd55f783f6a0cd', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
        title,
        imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
        page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
      }
      await sendChatMessageAllType('miniprogram', {
        miniprogram: { ...shareObj }
      })
    }
    return {
      showStatus,
      onShare
    }
  }
}
</script>

<style lang="less">
.order-item-header {
  line-height: 28px;
  border-bottom: 1px solid #f5f5f5;
}
.order-info-main {
  border-bottom: 1px solid #f5f5f5;
}
</style>
